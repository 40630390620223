import React from 'react';
import {NavLink} from "react-router-dom";

const Logo = () => {
    return (
        <div>
            <NavLink to="/" exact>
                <span className="logo">A2P</span>
            </NavLink>
        </div>
    );
};

export default Logo;