import React, { useState} from 'react';
import Navigation from "../components/Navigation";
import ButtonsBottom from "../components/ButtonsBottom";
import Logo from "../components/Logo";
import {variants, transition, leftVal, topVal, size} from "../paramAnim/Param";
import {motion} from "framer-motion";
//import { slideInUp } from "../components/animation/Animation";

import Graph from "../components/Methodologie/Graph";
import MethodologieDesc from "../components/Methodologie/MethodologieDesc";


export const Methodologie = () => {

    //Definit un hook pour gerer le point encours
    const [selectPoint, setSelectPoint] = useState(1);

    //Fonction permettant de recuperer la valeur de l'enfant de graph.js
    const receiveValueFromGraph = (value) => {
        setSelectPoint(value); //Met à jour le hook
        const els = document.getElementsByClassName("point");
        [].slice.call(els).map( (i)=>{
           return i.style.opacity = 0.6;
        }); //modifie l'opacité de tous les éléments
        document.getElementById("point_"+value).style.opacity = 1;
    }

    return (
        <main>
            <motion.div className="page"
                        initial="out"
                        animate="in"
                        exit="out"
                        variants={variants}
                        transition={transition}>
                <Navigation />
                <Logo />

                <div className="main decal">
                    <div className="left">

                        <h1>Méthodologie</h1>

                        <div className="spacer"></div>
                        <MethodologieDesc id={selectPoint}/>

                    </div>
                    <div className="right">


                            <Graph selectPoint={selectPoint} receiveValueFromGraph={receiveValueFromGraph}/>


                    </div>
                </div>
                <ButtonsBottom left={'/portfolios'} right={'experience'}/>
                <span className="random-circle" style={{left:leftVal, top:topVal, transform: size }}></span>
            </motion.div>
        </main>
    );
};
