import React  from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Masonry from "react-responsive-masonry";
import Modal from "react-modal";
import YouTube from 'react-youtube';
Modal.setAppElement("#root");

    
export const ProjectModal = (props) => {

    const opts = {
        height: '190',
        width: '290',
        playerVars: {
          // https://developers.google.com/youtube/player_parameters
          autoplay: 0,
        },
      }

     /* _onReady(event) {
        // access to player in all event handlers via event.target
        event.target.pauseVideo();
      }*/
  
    return (
        <Modal
            isOpen={props.isOpen}
            contentLabel="My dialog"
            className="mymodal"
            overlayClassName="myoverlay"
            closeTimeoutMS={500}
        >
            <FontAwesomeIcon className="btnClose" icon={faTimes}  />
            <div className="content">
                <div className="left">
                    <ul>
                        {props.el.languages.map( (item) => {
                            return <li key={item}>{item}</li>
                        })}
                    </ul>
                    {props.el.id !== 8 &&
                        <div className="logos">
                            {props.el.logo.map((item, i) => {
                                return <img key={i} src={item} alt={props.title}/>
                            })}
                        </div>
                    }
                </div>
                <div className="right">
                    <h1>{props.el.title}</h1>
                    <h2>{props.el.type}</h2>
                    <div className="desc" dangerouslySetInnerHTML={{ __html: props.el.desc }}></div>

{/* {props.el.img[0].type=="mov" && (<YouTube videoId={props.el.img[0].src} opts={opts} />)} */}
                        {props.el.hasOwnProperty('mov') && 
                            <Masonry className="mansory" columnsCount={2} gutter="10px">
                                {props.el.mov.map((image, i) => (
                                    <YouTube key={i} videoId={image.src} opts={opts} />
                                ))}
                        </Masonry>
                        }

                        {props.el.hasOwnProperty('img') && 
                            <Masonry className="mansory" columnsCount={4} gutter="10px">
                                {props.el.img.map((image, i) => (
                                
                                        <img
                                            key={i}
                                            src={image.src}
                                            alt={props.title}
                                            style={{width: "100%", display: "block"}}
                                        />
                                ))}
                            </Masonry>
                        }
                </div>
            </div>

        </Modal>
    )
}

export default ProjectModal;
