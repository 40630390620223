//Framer motion animations

export const fadeInContainer = {
    hidden: {
        opacity: 0
    },
    visible: {
        opacity: 1,
        transition: {
            duration: 0.25,
            type: "tween",
            ease: "easeIn",
            when: "beforeChildren",
            staggerChildren: 0.5
        }
    }
};

export const line1 = {
    hidden: {
        pathLength: 0,
        opacity: 0
    },
    visible: {
        pathLength: 1,
        opacity: 1,
        transition: {
            duration: 7,
            spring:10
        }
    }
};

export const point = {
    hidden: {
        y: 10,
        opacity: 0
    },
    visible: delay => ({
        y: 0,
        opacity: 1,
        transition: {
            delay: delay,
            duration: 0.5
        }
    })
};



export const line2 = {
    hidden: {
        pathLength: 0,
        opacity: 0
    },
    visible: {
        pathLength: 1,
        opacity: 1,
        transition: {
            delay: 7,
            duration: 1
        }
    }
};


export const slideInUp = {
    hidden: {
        y: 10,
        opacity: 0
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: {
            duration: 0.5
        }
    }
};

