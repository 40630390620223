import React from 'react';
import Navigation from "../components/Navigation";
import DynamicText from "../components/DynamicText";
import ButtonsBottom from "../components/ButtonsBottom";
import { motion } from "framer-motion";

const Home = () => {

    const variants = {
        initial:{
            opacity: 0,
            transition: { duration: 0.5},
            x:100
        },
        animate: {
            opacity: 1,
            x: 0
        },
        exit:{
            opacity:0,
            transition:{ duration:0.3},
            x:-100
        }
    }
    return (
        <main>
            <motion.div className="home"
                initial="initial"
                animate="animate"
                exit="exit"
                variants={variants}
            >
                <Navigation />
                <div className="home-main">
                    <div className="main-content">
                        <h1>A2P, Conception Web</h1>
                        <h2>Alban de Pommereau, développeur freelance</h2>
                        <h3><DynamicText /></h3>
                    </div>
                </div>
                <ButtonsBottom right={"/services"} />
            </motion.div>
        </main>
    );
};

export default Home;
