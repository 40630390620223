import React from 'react';
import {NavLink} from "react-router-dom";
import { bubble as Menu } from 'react-burger-menu'

const Navigation = () => {

    return (
        <div>
            <Menu>
                <NavLink to="/services" exact className="menu-item">Services</NavLink>
                <NavLink to="/outils" exact className="menu-item">Environnements</NavLink>
                <NavLink to="/portfolios" exact className="menu-item">Portfolios</NavLink>
                <NavLink to="/methodologie" exact className="menu-item">Méthodologie</NavLink>
                <NavLink to="/experience" exact className="menu-item">Expériences</NavLink>
                <NavLink to="/presentation" exact className="menu-item">Qui suis-je ?</NavLink>
                <NavLink to="/contact" exact className="menu-item">Contact</NavLink>
            </Menu>

            <div className="navigation">
                <ul>
                    <NavLink to="/services" exact className="hover" activeClassName="nav-active"><li>Services</li></NavLink>
                    <NavLink to="/outils" exact className="hover" activeClassName="nav-active"><li>Environnements</li></NavLink>
                    <NavLink to="/portfolios" exact className="hover" activeClassName="nav-active"><li>Portfolios</li></NavLink>
                    <NavLink to="/methodologie" exact className="hover" activeClassName="nav-active"><li>Méthodologie</li></NavLink>
                    <NavLink to="/experience" exact className="hover" activeClassName="nav-active"><li>Expériences</li></NavLink>
                    <NavLink to="/presentation" exact className="hover" activeClassName="nav-active"><li>Qui suis-je ?</li></NavLink>
                    <NavLink to="/contact" exact className="hover" activeClassName="nav-active"><li>Contact</li></NavLink>
                </ul>
            </div>
        </div>
    );
};

export default Navigation;
