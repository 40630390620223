export const presentationData = [
  {
    text: "<p>De formation initiale école de commerce (ECG - Ecole de Commerce et Gestion d'Orléans - CCI), j'ai débuté mon parcours professionnel en tant que chargé d'affaires \"grands comptes\" pour une société de vente de matériels informatiques.</p>",
    id: 1,
  },
  {
    text: "<p>Rapidement, les débuts de l'internet et le développement de ce nouveau support de communication ont attiré mon attention; tout d'abord comme canal de communication pour une entreprise mais surtout pour sa technologie.</p>",
    id: 2,
  },
  {
    text: "<p>Je décide de suivre une formation de \"Chef de Projets\" informatique au CESI (Ecole d'ingénieurs) et m'intéresse au langage du Web et son environnement. Pour cela, je fais mon mon stage de fin d'étude chez un \"FAI\" (Fournisseur d'Accès Internet). J'enrichi mon expérience par le développement de sites internet (HTML, graphisme...), m'initie à Java et je continue par des \"cours du soir\" sur d'autres langages (C, C++...).</p>",
    id: 3,
  },
  {
    text: "<p>A la suite de cette formation, je mets à disposition mes nouvelles compétences en créant une agence Web (Acc&s) à Orléans. Celle-ci réalise des sites vitrines, effectue du conseil en communication web et dispense des formations sur le fonctionnement et l'utilisation de cette nouvelle technologie (partenaire principale : CCI).</p>",
    id: 4,
  },
  {
    text: "<p>J'accentue mes connaissances sur les langages de programmation qui deviennent rapidement les langages d'avenir (Javascript, Php, Css...). J'effectue ainsi de nombreux développements de sites internet, intranet et extranet... tant sur l'aspect graphique / ergonomique que technique.</p>",
    id: 5,
  },
  {
    text: "<p>Pour un besoin personnel, je développe mon propre outil de gestion d'invités (ne trouvant pas de logiciels correspondants à mes attentes) ; Outil que je continue à enrichir par des fonctionnalités et décide de le rendre disponible sur le net (gratuitement). En constatant plus de 18 000 ouvertures de comptes dans les mois qui suivent, je lui donne le nom de \"Dooliz\" et sera géré par la SAS 0673 créée en 2015.</p>",
    id: 6,
  },
  {
    text: "<p>Dans le contexte de la crise sanitaire (2020 / 21) les événements sont à l'arrêt. Dooliz aussi ! Depuis, je mets mes compétences en tant que freelance et eu l'occasion d'effectuer des missions et aurais sans doute l'occasion de prendre en charge votre prochain projet.</p>",
    id: 7,
  }
];

