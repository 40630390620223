import React from "react";
import { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import emailjs, { init } from 'emailjs-com';
init("user_XycVRFC07AoYzbZK2Ya0J");

const ContactForm = () => {
    const [name, setName] = useState("");
    const [company, setCompany] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const [isLoading, setIsLoading] = useState(false);


    const handleSubmit = (e) => {
        e.preventDefault();

        setIsLoading(true);

        let nameS = document.getElementById("name");
        let emailS = document.getElementById("email");
        let messageS = document.getElementById("message");
        let formMess = document.querySelector(".form-message");

        const isEmail = () => {
            let isMail = document.getElementById("not-mail");
            let regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

            if (email.match(regex)) {
                isMail.style.display = "none";
                return true;
            } else {
                isMail.style.display = "block";
                isMail.style.animation = "dongle 1s";
                setTimeout(() => {
                    isMail.style.animation = "none";
                }, 1000);
                return false;
            }
        };

        if (name && isEmail() && message) {
            const templateId = "template_332qxjs";

            nameS.nextElementSibling.classList.remove("error");
            emailS.nextElementSibling.classList.remove("error");
            messageS.nextElementSibling.classList.remove("error");

            sendFeedback(templateId, {
                name,
                company,
                phone,
                email,
                message,
            });
        } else {
            formMess.innerHTML = "Merci de remplir correctement les champs requis *";
            formMess.style.background = "rgb(253, 87, 87)";
            formMess.style.display = "block";

            if (!name) nameS.nextElementSibling.classList.add("error");
            if (!email) emailS.nextElementSibling.classList.add("error");
            if (!message) messageS.nextElementSibling.classList.add("error");
            setIsLoading(false);
        }
    };

    const sendFeedback = (templateId, variables) => {
        let formMess = document.querySelector(".form-message");

        emailjs.send("service_ud53mej", templateId, variables)
            .then((res) => {
                formMess.innerHTML = "Message envoyé ! Je vous contacte dès que possible.";
                formMess.style.background = "#12bd40";
                formMess.style.display = "block";

                document.getElementById("name").nextElementSibling.classList.remove("error");
                document.getElementById("email").nextElementSibling.classList.remove("error");
                document.getElementById("message").nextElementSibling.classList.remove("error");
                setName("");
                setCompany("");
                setPhone("");
                setEmail("");
                setMessage("");

                setIsLoading(false);

                setTimeout(() => {
                    formMess.style.display = "none";
                }, 5000);
            })
            .catch(
                (err) => {
                    formMess.innerHTML = "Une erreur s'est produite, veuillez réessayer.";
                    formMess.style.background = "rgb(253, 87, 87)";
                    formMess.style.display = "block";
                    setIsLoading(false);
                }
            );
    };

    return (
        <form id="formContact" className="contact-form">

            <div className="form-content">

                <div className="float-label">
                    <input id="name" name="name" type="text" value={name} onChange={(e) => setName(e.target.value)} />
                    <label className={ name ? "active" : ""} htmlFor="name">
                        Nom *
                    </label>
                </div>

                <div className="float-label">
                    <input id="company" name="company" type="text" value={company} onChange={(e) => setCompany(e.target.value)}/>
                    <label className={ company ? "active" : ""} htmlFor="company">
                        Société
                    </label>
                </div>

                <div className="float-label">
                    <input id="phone" name="phone" type="text" value={phone} onChange={(e) => setPhone(e.target.value)}/>
                    <label className={ phone ? "active" : ""} htmlFor="phone">
                        Téléphone
                    </label>
                </div>

                <div className="float-label">
                    <label id="not-mail">Email non valide</label>
                    <input id="email" name="email" type="email" value={email} onChange={(e) => setEmail(e.target.value)}/>
                    <label className={ email ? "active" : ""} htmlFor="email">
                        E-mail *
                    </label>
                </div>

                <div className="float-label">
                    <textarea id="message" name="message" rows="30" value={message} onChange={(e) => setMessage(e.target.value)} required/>
                    <label className={ message ? "active" : ""} htmlFor="message">
                        Message *
                    </label>
                </div>


            </div>

            <div className="form-message"></div>
            <button
                className="hover"
                type="submit"
                onClick={handleSubmit}
            >
                {isLoading && (
                    <FontAwesomeIcon icon={faSpinner} spin />
                )}
                {isLoading && <span style={{marginLeft:10+'px'}}>Envoi en cours</span>}
                {!isLoading && <span>Envoyer</span>}
            </button>

        </form>
    );
};

export default ContactForm;
