import React from 'react';
import { MethodologieData } from '../../data/methodologieData';


const MethodologieDesc = (props) => {
    const id = props.id ? props.id : 0;
    return (
        <div>
            <h3>{MethodologieData[id].titre}</h3>
            <div className="spacer"></div>
            <div dangerouslySetInnerHTML={{__html: MethodologieData[id].text}}></div> {/*Html Format*/}

        </div>
    );
};

export default MethodologieDesc;