/*import React from 'react';*/

export const variants = {
    in:{ opacity: 1, x:0},
    out:{opacity:0, x:300}
}
export const transition = {
    ease:[0.03, 0.87, 0.73, 0.9],
    duration : 0.6
}


function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

const { height, width } = getWindowDimensions();

export let leftVal = Math.floor(Math.random() * 20 + (width-400)) + "px";
export let topVal = Math.floor(Math.random() * 20 + (height-400)) + "px";
export let size = "scale(" + Math.random() + 0.7 +  ")";