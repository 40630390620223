import React from 'react';
import { NavLink} from "react-router-dom";

const ButtonsBottom = (props) => {
    //console.log(props);
    return (
        <div className="bottom">
            <div className="scroll-bottom">
                <div className="sb-main">
                    {props.left && (
                        <NavLink to={props.left} className="left hover">
                            <span>&#10092;</span>
                        </NavLink>
                    )}
                    <p className="center">&nbsp;</p>
                    {props.right && (
                        <NavLink to={props.right} className="right hover">
                            <span>&#10093;</span>
                        </NavLink>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ButtonsBottom;
