import React from 'react';
import { motion } from "framer-motion";

import Navigation from "../components/Navigation";
import ButtonsBottom from "../components/ButtonsBottom";
import Logo from "../components/Logo";
import { variants, transition, leftVal, topVal, size } from "../paramAnim/Param";

import vignette from "../assets/img/IMG_7203.jpg"
import { presentationData } from "../data/presentationData";


export const Presentation = () => {

    const variantsParaf = {
        visible: (i) => ({
            opacity: 1,
            transition: {
                delay: i * 0.3
            }
        }),
        hidden: { opacity: 0 }
    };

    return (
        <main>
            <motion.div className="page"
                initial="out"
                animate="in"
                exit="out"
                variants={variants}
                transition={transition}>
                <Navigation />
                <Logo />


                <div className="main">

                    <div className="left">
                        <h1>Qui suis-je ?</h1>
                        <div className="vignette" style={{ backgroundImage: `url(${vignette})` }}>kljlk</div>

                        <div className="spacer"></div>

                        <ul>
                            <li>Organisé,</li>
                            <li>Rigoureux,</li>
                            <li>Autonome, </li>
                            <li>Esprit d'analyse, de synthèse,</li>
                            <li>Respectueux des délais.</li>
                            <li>Créatif, force de proposition.</li>
                        </ul>
                    </div>
                    <div className="right">

                            {presentationData.map(({ text, id }, i) => (
                                <motion.p
                                    key={id}
                                    initial="hidden"
                                    custom={i}
                                    animate="visible"
                                    variants={variantsParaf}
                                >
                                    <span dangerouslySetInnerHTML={{ __html: text }}></span> {/*Html Format*/}
                                </motion.p>
                            ))}

                    </div>
                </div>


                <ButtonsBottom left={'/experience'} right={'/contact'} />
                <span className="random-circle" style={{ left: leftVal, top: topVal, transform: size }}></span>
            </motion.div>
        </main>
    );
};

