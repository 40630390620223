import React from 'react';
import {motion} from "framer-motion";
import Navigation from "../components/Navigation";
import ButtonsBottom from "../components/ButtonsBottom";
import Logo from "../components/Logo";
import {variants, transition, leftVal, topVal, size} from "../paramAnim/Param";
import Timeline from "../components/Timeline";

export const Experience = () => {

    return (
        <main>
            <motion.div className="page"
                        initial="out"
                        animate="in"
                        exit="out"
                        variants={variants}
                        transition={transition}>
                <Navigation />
                <Logo />

                <div className="main decal">
                    <div className="left">
                        <h1>Expériences</h1>
                        <h2>et formations</h2>

                        <div className="spacer"></div>

                        <p>Par un cursus entrepreneurial, j'ai le sens des responsabilités et de l'organisation.</p>

                        <p>Ma capacité d'adaptation et d'analyse me permet d'aborder les projets avec efficacité. Naturellement curieux, je m’implique dans la reflexion et la créativité pour apporter un regard extérieur sur la problématique client.</p>

                        <p>Les expériences acquises, menées durant ce parcours professionel, me donnent un « savoir-faire » sur la conduite de projet, la « gestion timing », l’organisation des tâches et la réalisation technique.</p>
                    </div>

                    <div  className="right">
                        <Timeline/>
                    </div>
                </div>


                <ButtonsBottom left={'/methodologie'} right={'/presentation'} />
                <span className="random-circle" style={{left:leftVal, top:topVal, transform: size }}></span>
            </motion.div>
        </main>
    );
}
