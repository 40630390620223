export const projectsData = {
  'detail': [
    {
      id: 1,
      title: "Trinnov Audio",
      title2: 'Correction acoustique',
      type: "Intranet / Extranet",
      languages: ["VueJs", "Vuetify", "Auth0", "Php", "MySql","API","Symfony","Redis", "WebSocket"],
      infos: "Intranet",
      desc: "<p>Refonte globale de l'intranet/extranet de MyTrinnov.com. Que cela soit pour les utilisateurs Trinnov (commercial / support) ou les clients utilisateur, le MyTrinnov.com est l'interface de gestion. Elle permet de gérer toutes les informations relatives aux clients.</p><p>Développement d'une infrastructure complexe en multi-domaines : service Utilisateur (gestion des entreprises, contacts, marchés, délégations...), service Licence (génération et gestion des licences machine...), service Machines (gestion technique des machines, prise de contrôle,mise à jour, options, ...), ... Ces services sont sous forme d'API et sont, selon les besoins, inter-connectés. </p><p>Côté front-office, développement d'une interface pour communiquer et présenter les informations provenants de ces services.</p><p>De nombreuses données communiquent avec des tiers (Copper, Hubspot, S3, ...), ainsi que la Trinnov App (application mobile).</p><p><i>Mission toujours en cours...</i></p>",
      logo: ["./assets/client/trinnov_2/mytrinnov_logo.svg"],
      img: [
        {src: "./assets/client/trinnov_2/support_1.png", width: 1342, height: 875},
        {src: "./assets/client/trinnov_2/support_2.png", width: 1346, height: 918},
        {src: "./assets/client/trinnov_2/machine_detail.png", width: 1312, height: 1083},
        {src: "./assets/client/trinnov_2/machine_detail_2.png", width: 1345, height: 917},
        {src: "./assets/client/trinnov_2/machine_detail_3.png", width: 1348, height: 915},
        {src: "./assets/client/trinnov_2/license.png", width: 1344, height: 914},
        {src: "./assets/client/trinnov_2/license_detail.png", width: 1343, height: 918}
      ],
      link: null,
    },
    {
      id: 2,
      title: "Trinnov Audio",
      title2: 'Correction acoustique',
      type: "IHM",
      languages: ["VueJs", "Vuetify"],
      infos: "Intranet",
      desc: "<p>Dans le cadre de la mise sur le marché du nouveau produit NOVA de la gamme Trinnov Audio, je collabore à la conception de l'interface Homme/Machine.</p>",
      logo: ["./assets/client/trinnov_1/trinnov_logo.svg"],
      mov: [
        {src: "UknxqbViWWE"},
        {src: "kU8W4mO7faU"},
      ],
      img: [
        {src: "./assets/client/trinnov_1/index.jpg", width: 1868, height: 868},
        {src: "./assets/client/trinnov_1/list.jpg", width: 1367, height: 862},
        {src: "./assets/client/trinnov_1/calibration_1.jpg", width: 1367, height: 861},
        {src: "./assets/client/trinnov_1/calibration_2.jpg", width: 1363, height: 858},
        {src: "./assets/client/trinnov_1/optimizer_1.jpg", width: 1361, height: 860},
        {src: "./assets/client/trinnov_1/graph_1.jpg", width: 1365, height: 860},
        {src: "./assets/client/trinnov_1/autre.jpg", width: 1363, height: 858},
      ],
      link: "https://www.trinnov.com/fr/produits/nova"
    },
      {
        id: 3,
        title: "Dooliz",
        title2: 'Gestionnaire d\'invités',
        type: "Internet",
        languages: ["Php", "MySql", "SQlite", "JQuery", "Boostrap", "React", "Raspberry Pi", "NodeJs", "SMTP", "..."],
        infos: "Intranet",
        desc: "<p>Suite à un besoin personnel Dooliz est lancé en 2015 et a contribué a accompagner près de 60 000 événements. Répondant aussi bien aux particuliers qu'aux professionnels (Shisheido, Préfecture de Paris, BPE, Crédit Mutuel, Shimano, Laboratoire Philorga...)</p><p>Mise en place d'une architecture élaboré permettant une totale indépendance entre les événements et leur gestion.</p><p>Le service propose la gestion des invités, la conception et l'envoi d'invitation, la réponse en ligne, la conception et la mise en ligne automatisé d'un site internet de présentation de l'événement, la gestion multi-langue, la création et l'édition de badge, les outils nécessaires pour le check-in... et une plateforme d'administration pour sa gestion</p>",
        logo: ["./assets/client/dooliz/dooliz.svg"],
        img: [
          {src: "./assets/client/dooliz/contact.png", width: 1307, height: 1225},
          {src: "./assets/client/dooliz/carte.png", width: 1313, height: 1099},
          {src: "./assets/client/dooliz/carton.png", width: 1312, height: 1083},
          {src: "./assets/client/dooliz/stat.png", width: 1315, height: 457},
          {src: "./assets/client/dooliz/tf.png", width: 1312, height: 637},
          {src: "./assets/client/dooliz/organisateur.png", width: 1312, height: 637},
         // {src: "./assets/client/dooliz/admin1.png", width: 1312, height: 637},
          {src: "./assets/client/dooliz/admin2.png", width: 1312, height: 637}
        ],
        link: null,
      },
      {
        id: 4,
        title: "Trinnov Audio",
        title2: 'Correction acoustique',
        type: "Intranet",
        languages: ["VueJs", "Vuetify", "Firebase"],
        infos: "Intranet",
        desc: "<p>En collaboration avec l'équipe en charge du développement de l'API, j'interviens principalement sur l'ergonomie et l'intégration des données en VueJS.</p><p>Contrôle des machines distantes, gestion des paramètres machine. Gestion des distributeurs et leur réseau de distribution. Synthèse des machines associées.</p>",
        logo: ["./assets/client/trinnov/trinnov_logo.svg"],
        img: [
          {src: "./assets/client/trinnov/trinnov_1.png", width: 1307, height: 1225},
          {src: "./assets/client/trinnov/trinnov_3.png", width: 1313, height: 1099},
          {src: "./assets/client/trinnov/trinnov_4.png", width: 1312, height: 1083},
          {src: "./assets/client/trinnov/trinnov_5.png", width: 1315, height: 457},
          {src: "./assets/client/trinnov/trinnov_6.png", width: 1312, height: 637}
        ],
        link: null,
      },
      {
        id: 5,
        title: "Diafora",
        title2: "Organisme de formation",
        type: "Intranet",
        languages: ["Php", "MySql", "JQuery", "Bootstrap"],
        infos:
            "Intranet",
        desc:
            "<p>Etude, conception et réalisation du besoin de création d'un outil interne pour améliorer la gestion du coeur métier. </p><p>Gestion des comptes clients, des formateurs et étudiants. Planning des cours, affiliation des étudiants. Statistiques, générateur de documents et certificats.</p>",
        logo: ["./assets/client/diafora/diafora.svg"],
        img: [
          {src: "./assets/client/diafora/diafora_1.png", width: 1264, height: 981},
          {src: "./assets/client/diafora/diafora_2.png", width: 1249, height: 1231},
          {src: "./assets/client/diafora/diafora_3.png", width: 1262, height: 885},
          {src: "./assets/client/diafora/diafora_4.png", width: 1306, height: 1206},
          {src: "./assets/client/diafora/diafora_5.png", width: 1300, height: 1243},
          {src: "./assets/client/diafora/diafora_6.png", width: 1312, height: 1200},
          {src: "./assets/client/diafora/diafora_7.png", width: 1315, height: 1151},
          {src: "./assets/client/diafora/diafora_8.png", width: 1314, height: 1100},
          {src: "./assets/client/diafora/diafora_9.png", width: 1318, height: 1166},
          {src: "./assets/client/diafora/diafora_10.png", width: 1312, height: 1095},
          {src: "./assets/client/diafora/diafora_11.png", width: 1301, height: 1210}
        ],
        link: null,
      },
      {
        id: 6,
        title: "GrDF",
        title2: "Service cartographie",
        type: "Extranet",
        languages: ["Php", "MySql", "JQuery"],
        infos:
            "Extranet",
        desc:
            "<p>Outil permettant l'organisation et le partage d'information concernant la gestion des chantiers.</p><p>Organisation chronologique des différents acteurs, partage et validation de documents entre services, respect des \"normes chantier\".</p>",
        logo: ["./assets/client/grdf/grdf.svg"],
        img: [
          {src: "./assets/client/grdf/grdf_carto_1.jpg", width: 600, height: 334},
          {src: "./assets/client/grdf/grdf_carto_2.jpg", width: 600, height: 405},
          {src: "./assets/client/grdf/grdf_carto_3.jpg", width: 600, height: 394},
          {src: "./assets/client/grdf/grdf_carto_4.jpg", width: 600, height: 394},
          {src: "./assets/client/grdf/grdf_carto_5.jpg", width: 600, height: 412},
          {src: "./assets/client/grdf/grdf_carto_6.jpg", width: 600, height: 393},
        ],
        link: null,
      },
      {
        id: 7,
        title: "Biarritz Olympique",
        title2: "Rugby Top 16",
        type: "Internet",
        languages: ["Php", "MySql", "Javascript"],
        infos: "Internet",
        desc:
            "Présentation de l'équipe, publication quotidienne d'informations, flux Xml, gestion des matchs en direct.<br/> Client : Cap Gemini (sponsor principal). <br/>Back Office : Partadmin",
        logo: ["./assets/client/biarritz/biarritz.svg", "./assets/client/cap-gemini.svg"],
        img: [
          {src: "./assets/client/biarritz/biarritz_1.png", width: 943, height: 1181},
          {src: "./assets/client/biarritz/biarritz_2.png", width: 727, height: 981},
          {src: "./assets/client/biarritz/biarritz_4.png", width: 725, height: 638},
          {src: "./assets/client/biarritz/biarritz_9.png", width: 908, height: 1070},
          {src: "./assets/client/biarritz/biarritz_11.png", width: 951, height: 705},
          {src: "./assets/client/biarritz/biarritz_3.png", width: 725, height: 587},
          {src: "./assets/client/biarritz/biarritz_7.png", width: 947, height: 1028},
          {src: "./assets/client/biarritz/biarritz_8.png", width: 952, height: 1196},
          {src: "./assets/client/biarritz/biarritz_5.png", width: 1084, height: 844},
          {src: "./assets/client/biarritz/biarritz_6.png", width: 1084, height: 848},
          {src: "./assets/client/biarritz/biarritz_10.png", width: 1017, height: 752},
        ],
        link: null,
      },
      {
        id: 8,
        title: "Géronimo",
        title2: "Trophée Jules Verne - Olivier de Kersauson",
        type: "Internet",
        languages: ["Php", "MySql", "Javascript"],
        infos: "Internet",
        desc:
            "<p>Sous la direction du service sponsoring de Cap Gemini, je suis en charge de la réalisation techique du site internet et de l'intégration de la charte graphique.</p> <p>Le site présente toutes l'actualités quotidiennes durant les 62 jours de courses, de l'équipage, du bateau et emet les vacations en direct</p><p>Client : Cap Gemini et Shneider Electric. <br/>Back Office : Partadmin</p>",
        logo: ["./assets/client/geronimo/jules_verne.svg", "./assets/client/cap-gemini.svg", "./assets/client/schneider-electric.svg"],
        img: [
          {src: "./assets/client/geronimo/realisation_geronimo1.png", width: 943, height: 1181},
          {src: "./assets/client/geronimo/realisation_geronimo2.png", width: 727, height: 981},
          {src: "./assets/client/geronimo/realisation_geronimo3.png", width: 725, height: 587},
          {src: "./assets/client/geronimo/realisation_geronimo4.png", width: 725, height: 638},
        ],
        link: null,
      },
      {
        id: 9,
        title: "Autres",
        title2: "réalisations...",
        type: "",
        languages: [],
        infos: null,
        desc:
            "De nombreux sites ont été réalisés il y a bien longtemps maintenant : site \"vitrine\" Présentation de l'équipe, publication quotidienne d'informations, flux Xml, gestion des matchs en direct.<br/> Client : Cap Gemini (sponsor principal). <br/>Back Office : Partadmin",
        logo: ["./assets/client/pointille.svg"],
        img: [
          {src: "./assets/client/bouygues-construction.svg", width: 943, height: 1181},
          {src: "./assets/client/dior.svg", width: 727, height: 981},
          {src: "./assets/client/frasem.png", width: 725, height: 587},
          {src: "./assets/client/dimezzo.png", width: 725, height: 638},
          {src: "./assets/client/100fret.png", width: 725, height: 638},
          {src: "./assets/client/egylis.png", width: 725, height: 638},
          {src: "./assets/client/holocation.png", width: 725, height: 638},
          {src: "./assets/client/jecraquebio.png", width: 725, height: 638},

        ],
        link: null,
      }
    ],
  'liste': [
            {
        title: "Loxos",
        type: "Internet",
        languages: ["Php", "MySql", "JQuery"],
        desc: "<p>Conception du site vitrine - Back Office : <b>Partadmin</b><sup>*</sup> -, en partenariat avec l'agence Think Ad (graphisme).</p>",
        logo: ["./assets/client/loxos/loxos.svg"],
        img: [
          {src: "./assets/client/loxos/loxos1.jpg", width: 600, height: 334},
          {src: "./assets/client/loxos/loxos2.jpg", width: 600, height: 405},
          {src: "./assets/client/loxos/loxos3.png", width: 600, height: 394},
          {src: "./assets/client/loxos/loxos4.jpg", width: 600, height: 394},
          {src: "./assets/client/loxos/loxos5.jpg", width: 600, height: 412},
          {src: "./assets/client/loxos/loxos6.jpg", width: 600, height: 393},
        ],
        link: null,
      },
    {
      title: "Dimezzo",
      type: "Internet et Intranet",
      languages: ["Php", "MySql", "JQuery"],
      desc: "<p>Conception du site - Back Office : <b>Partadmin</b><sup>*</sup> -, et un intranet pour la réservation de salles / gestion interne.</p>",
      logo: ["./assets/client/dimezzo.png"],
      img: [
        {src: "./assets/client/loxos/loxos1.jpg", width: 600, height: 334},
        {src: "./assets/client/loxos/loxos2.jpg", width: 600, height: 405},
        {src: "./assets/client/loxos/loxos3.png", width: 600, height: 394},
        {src: "./assets/client/loxos/loxos4.jpg", width: 600, height: 394},
        {src: "./assets/client/loxos/loxos5.jpg", width: 600, height: 412},
        {src: "./assets/client/loxos/loxos6.jpg", width: 600, height: 393},
      ],
      link: null,
    },
    {
      title: "Frasem",
      type: "Internet",
      languages: ["Php", "MySql", "JQuery"],
      desc: "<p>Conception du site. Back Office : <b>Partadmin</b><sup>*</sup></p>",
      logo: ["./assets/client/frasem.png"],
      img: [
        {src: "./assets/client/loxos/loxos1.jpg", width: 600, height: 334},
        {src: "./assets/client/loxos/loxos2.jpg", width: 600, height: 405},
        {src: "./assets/client/loxos/loxos3.png", width: 600, height: 394},
        {src: "./assets/client/loxos/loxos4.jpg", width: 600, height: 394},
        {src: "./assets/client/loxos/loxos5.jpg", width: 600, height: 412},
        {src: "./assets/client/loxos/loxos6.jpg", width: 600, height: 393},
      ],
      link: null,
    },
    {
      title: "100% Fret ",
      type: "Internet et intranet",
      languages: ["Php", "MySql", "JQuery"],
      desc: "<p>Conception du site Back Office : <b>Partadmin</b><sup>*</sup> et conception de l'intranet pour la gestion clients et l'automatisation de calcul de devis.</p>",
      logo: ["./assets/client/100fret.png"],
      img: [
        {src: "./assets/client/bouygues-construction.svg", width: 943, height: 1181},
        {src: "./assets/client/dior.svg", width: 727, height: 981},
        {src: "./assets/client/frasem.png", width: 725, height: 587},
        {src: "./assets/client/dimezzo.png", width: 725, height: 638},
        {src: "./assets/client/100fret.png", width: 725, height: 638},
        {src: "./assets/client/egylis.png", width: 725, height: 638},
        {src: "./assets/client/holocation.png", width: 725, height: 638},
        {src: "./assets/client/jecraquebio.png", width: 725, height: 638},

      ],
      link: null,
    },
    {
      title: "Bouygues Construction",
      type: "Internet",
      languages: ["Html", "css"],
      desc: "<p>Réalisations de multiples sites \"vitrines\" pour les filiales du groupe.</p>",
      logo: ["./assets/client/bouygues-construction.svg"],
      img: [
        {src: "./assets/client/bouygues-construction.svg", width: 943, height: 1181},
        {src: "./assets/client/dior.svg", width: 727, height: 981},
        {src: "./assets/client/frasem.png", width: 725, height: 587},
        {src: "./assets/client/dimezzo.png", width: 725, height: 638},
        {src: "./assets/client/100fret.png", width: 725, height: 638},
        {src: "./assets/client/egylis.png", width: 725, height: 638},
        {src: "./assets/client/holocation.png", width: 725, height: 638},
        {src: "./assets/client/jecraquebio.png", width: 725, height: 638},

      ],
      link: null,
    },
    {
      title: "Ho Location ",
      type: "Internet",
      languages: ["Html", "css"],
      desc: "<p>Conception du site</p>",
      logo: ["./assets/client/holocation.png"],
      img: [
        {src: "./assets/client/bouygues-construction.svg", width: 943, height: 1181},
        {src: "./assets/client/dior.svg", width: 727, height: 981},
        {src: "./assets/client/frasem.png", width: 725, height: 587},
        {src: "./assets/client/dimezzo.png", width: 725, height: 638},
        {src: "./assets/client/100fret.png", width: 725, height: 638},
        {src: "./assets/client/egylis.png", width: 725, height: 638},
        {src: "./assets/client/holocation.png", width: 725, height: 638},
        {src: "./assets/client/jecraquebio.png", width: 725, height: 638},

      ],
      link: null,
    },
    {
      title: "",
      type: "",
      languages: [],
      desc: "<p>et plus encore..., Egylis, Miyowa, Proservie, CRIJ, Cérazur, SMTLV, Socofer, GDR Consulting, Think Ad Communication, Mandel, etc.</p>",
      logo: [],
      img: [
        {src: "./assets/client/bouygues-construction.svg", width: 943, height: 1181},
        {src: "./assets/client/dior.svg", width: 727, height: 981},
        {src: "./assets/client/frasem.png", width: 725, height: 587},
        {src: "./assets/client/dimezzo.png", width: 725, height: 638},
        {src: "./assets/client/100fret.png", width: 725, height: 638},
        {src: "./assets/client/egylis.png", width: 725, height: 638},
        {src: "./assets/client/holocation.png", width: 725, height: 638},
        {src: "./assets/client/jecraquebio.png", width: 725, height: 638},

      ],
      link: null,
    },
    ]
}

;
