import React  from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { projectsData } from '../data/projectsData';
import Modal from "react-modal";
Modal.setAppElement("#root");

export const ProjectModalListe = (props) => {

    return (
        <Modal
            isOpen={props.isOpen}
            contentLabel="My dialog"
            className="mymodal"
            overlayClassName="myoverlay"
            closeTimeoutMS={500}
        >
            <FontAwesomeIcon className="btnClose" icon={faTimes}  />

            {projectsData['liste'].map((item, i) => (
                <div className="liste" key={i}>
                    <div className="left">
                        <img src={item.logo[0]} alt={item.title} />
                    </div>
                    <div className="right">
                        <h4>{item.title} <span>{item.type ? item.type+' |' : '' } {item.languages.join(' - ')}</span></h4>
                        <p dangerouslySetInnerHTML={{ __html: item.desc}}></p>
                    </div>
                </div>
            ))
            }

            <div className="liste" >
                <div className="left">
                    <img src="./assets/client/partadmin.png" alt="Partadmin"/>
                </div>
                <div className="right">
                    <p><i><br/>: CMS développé pour la gestion des sites (avant l'arrivé des outils comme WordPress...)</i></p>
                </div>
            </div>


        </Modal>
    )
}

export default ProjectModalListe;
