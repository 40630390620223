import React, {useState} from 'react';
import Modal from "react-modal";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes, faFileDownload,faSpinner} from "@fortawesome/free-solid-svg-icons";

import pdf from '../assets/CV_Alban_de_pommereau.pdf'

import emailjs, { init } from 'emailjs-com';
init("user_XycVRFC07AoYzbZK2Ya0J");


const CVForm = (props) => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [isLoading, setIsLoading] = useState(false);


    const handleSubmit = (e) => {
        e.preventDefault();
        let nameS = document.getElementById("nameCV");
        let emailS = document.getElementById("emailCV");
        let formMess = document.querySelector(".form-messageCV");

        const isEmail = () => {
            let isMail = document.getElementById("not-mailCV");
            let regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
            if (email.match(regex)) {
                isMail.style.display = "none";
                return true;
            } else {
                isMail.style.display = "block";
                isMail.style.animation = "dongle 1s";
                setTimeout(() => {
                    isMail.style.animation = "none";
                }, 1000);
                return false;
            }
        };

        if (name && isEmail()) {
            const templateId = "template_f6hmce5";
            nameS.nextElementSibling.classList.remove("error");
            emailS.nextElementSibling.classList.remove("error");
            sendFeedbackCV(templateId, {
                name,
                email,
            });
        } else {
            formMess.innerHTML = "Merci de remplir correctement les champs requis *";
            formMess.style.background = "rgb(253, 87, 87)";
            formMess.style.display = "block";
            if (!name) nameS.nextElementSibling.classList.add("error");
            if (!email) emailS.nextElementSibling.classList.add("error");
            setIsLoading(false);
        }
    };



    const sendFeedbackCV = (templateId, variables) => {

        emailjs.send("service_ud53mej", templateId, variables)
            .then((res) => {
                document.getElementById("replace").style.display = "none";
                document.getElementById("link").style.display = "block";
                setIsLoading(false);
            })
            .catch(
                (err) => {
                    const formMess = document.querySelector(".form-messageCV");
                    formMess.innerHTML = "Une erreur s'est produite, veuillez réessayer.";
                    formMess.style.background = "rgb(253, 87, 87)";
                    formMess.style.display = "block";
                    setIsLoading(false);
                }
            );
    };



    return (
            <Modal
                isOpen={props.isOpen}
                onRequestClose={ props.action }
                contentLabel="CV Formulaire"
                className="mymodal cvModal"
                overlayClassName="myoverlay"
                closeTimeoutMS={500}
            >
                <FontAwesomeIcon className="btnClose" icon={faTimes} onClick={ props.action } />

                <div id="replace">
                    <p>Veuillez indiquer votre nom et email.</p>
                        <form id="formCV" className="contact-form cv">

                            <div className="form-content cv">

                                <div className="float-label">
                                    <input id="nameCV" name="name" type="text" value={name} onChange={(e) => setName(e.target.value)}/>
                                    <label className={ name ? "active" : ""} htmlFor="nameCV">
                                        Nom *
                                    </label>
                                </div>

                                <div className="float-label">
                                    <label id="not-mailCV">Email non valide</label>
                                    <input id="emailCV" name="email" type="email" value={email} onChange={(e) => setEmail(e.target.value)}/>
                                    <label className={ email ? "active" : ""} htmlFor="emailCV">
                                        E-mail *
                                    </label>
                                </div>
                                <div className="form-messageCV"></div>
                                <button className="hover" type="submit" onClick={handleSubmit}>
                                    {isLoading && (
                                        <FontAwesomeIcon icon={faSpinner} spin />
                                    )}
                                    {isLoading && <span style={{marginLeft:10+'px'}}>Traitement...</span>}
                                    {!isLoading && <span>Valider</span>}
                                </button>
                            </div>



                        </form>
                </div>

                <div id="link">
                    Merci ! Vous pouvez télécharger le fichier pdf qui reprend mon expérience et mes coordonnées.<br/>
                    <br/>
                    <a href={pdf} download="CV_Alban_de_Pommereau.pdf" onClick={props.action}><FontAwesomeIcon icon={faFileDownload}/> Télécharger mon CV</a>

                    <br/>
                    <br/>
                    A bientôt.

                </div>

            </Modal>
    );
};

export default CVForm;
