import React from 'react';
import { motion } from "framer-motion";

import Navigation from "../components/Navigation";
import ButtonsBottom from "../components/ButtonsBottom";
import Logo from "../components/Logo";
import { variants, transition, leftVal, topVal, size } from "../paramAnim/Param";

import { OutilsData } from '../data/outilsData';
import Language from "../components/Outils/Language";
//import Gauge from "../components/Outils/Gauge";


export const Outils = () => {

    //console.log(OutilsData['minor']);
    //const myData = [].concat(OutilsData)
    //    .sort((a, b) => a.progress < b.progress ? 1 : -1)

    return (

        <main>
            <motion.div className="page"
                initial="out"
                animate="in"
                exit="out"
                variants={variants}
                transition={transition}>
                <Navigation />
                <Logo />

                <div className="main decal">
                    <div className="left">
                        <h1>Environnements</h1>
                        <h2>de programmation</h2>

                        <div className="spacer"></div>
                        <p>Cette liste (non exhaustive) représente les domaines dans lesquels j'ai l'habitude d'évoluer.</p>
                        <p>Curieux par nature, j'actualise mes compétences avec les nouvelles technologies et me forme ainsi aux langages qui se présentent.</p>
                        <p>Fort de mes nombreuses années de développement, je suis à même de prendre possesion d'un nouveau langage pour un besoin spécifique.</p>

                        <div className="spacer"></div>




                    </div>
                    <div className="right">

                        <div className="language">
                            <ul>
                                {OutilsData['major'].map((item, i) => {
                                    return <Language cat="major" id={item.id} key={i} />
                                })}
                            </ul>

                            <div className="spacer"></div>
                            <h4>A l'étude</h4>
                            <ul>
                                {OutilsData['minor'].map((item, i) => {
                                    return <Language cat="minor" id={item.id} key={i} />
                                })}
                            </ul>

                        </div>
                        <div className="spacer"></div>



                    </div>


                </div>
                <ButtonsBottom left={'/services'} right={'/portfolios'} />
                <span className="random-circle" style={{ left: leftVal, top: topVal, transform: size }}></span>
            </motion.div>
        </main>

    );
};

