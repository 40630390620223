import React from 'react'
import styled, { keyframes } from "styled-components";
import BounceIn from "react-animations/lib/bounce-in-down";

import {OutilsData} from '../../data/outilsData';

class Language extends React.Component{

    findElement(array, id) {
        return array.find((element) => {
            return element.id === id;
        })
    }

    render() {

        const random = Math.floor(Math.random() * 6) + 2;  // returns a random integer from 1 to 10
        const BounceInAnimation = keyframes`${BounceIn}`;
        const BounceInLi = styled.li`animation: ${random}s ${BounceInAnimation};`;

        var el = this.findElement(OutilsData[this.props.cat], this.props.id);

        return (
            <BounceInLi >
                <div className="icon" width={50}>
                    <img alt={el.title} src={el.img}/>
                </div>
               {el.title}
            </BounceInLi>
        )
    }
}

export default Language