import React, { useState }  from 'react'
import styled, { keyframes } from "styled-components";
import BounceIn from "react-animations/lib/bounce-in-right";
import { projectsData } from '../data/projectsData';
import ProjectModal from "./ProjectModal";
import ProjectModalListe from "./ProjectModalListe";

function findElement(array, id) {
    return array.find((element) => {
        return element.id === id;
    })
}

export const Portfolio = (props) => {

    const [isOpen, setIsOpen] = useState(false);
    const random = Math.floor(Math.random() * 6) + 2;  // returns a random integer from 1 to 10
    const BounceInAnimation = keyframes`${BounceIn}`;
    const BounceInLi = styled.li`animation: ${random}s ${BounceInAnimation};`;

    var el = findElement(projectsData.detail, props.id);
    return (
        <BounceInLi onClick= { ()=> setIsOpen(!isOpen) }>
            <div className="icon" >
                <img alt={el.title} src={el.logo[0]} />
            </div>

            <div className="other" >
                <div className="type">{el.type}</div>
                <div className="title">{el.title}</div>
                <div className="title2">{el.title2}</div>
            </div>

            {el.id === 8 ?
                <ProjectModalListe isOpen={isOpen}/>
                :
                <ProjectModal el={el} isOpen={isOpen}/>
            }

        </BounceInLi >
    )
};

export default Portfolio;
