import React from 'react';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import WorkIcon from '@material-ui/icons/Work';
import SchoolIcon from '@material-ui/icons/School';
import StarIcon from '@material-ui/icons/Star';


const Timeline = () => {
    return (
        <div>

            <VerticalTimeline layout="1-column-left">
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                    contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                    date="Maintenant"
                    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                    icon={<WorkIcon />}
                >
                    <div className="listLogo"><img alt="A2P" src="/assets/client/a2p.svg" width={100}/> </div>
                    <h3 className="vertical-timeline-element-title">Freelance </h3>
                    <p>
                        En charge de vos futurs projets
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="2016 - 2021"
                    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                    icon={<WorkIcon />}
                >
                    <div className="listLogo"><img alt="0673" src="/assets/client/0673.svg" width={50}/> <img alt="Dooliz" src="/assets/client/dooliz/dooliz.svg" width={120}/></div>
                    <h3 className="vertical-timeline-element-title">Co-fondateur - Directeur Général</h3>
                    <h4 className="vertical-timeline-element-subtitle">O673 - Marketing Digital</h4>
                    <p>
                        Et principalement directeur du développement de "<b>Dooliz - gestionnaire d'invités</b>" (web service). Développement des sites, évolution, maintenance
                    </p>

                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="2004 - 2016"
                    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                    icon={<WorkIcon />}
                >
                    <div className="listLogo"><img alt="PartAdmin" src="/assets/client/partadmin.png" /> </div>
                    <h3 className="vertical-timeline-element-title">Freelance</h3>

                    <p>
                        Développement de site internet / intranet / extranet<br/>
                        Conseil en communication Web
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="1999 - 2004"
                    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                    icon={<WorkIcon />}
                >
                    <div className="listLogo"><img alt="ACC&S" src="/assets/client/accxs.png" /> </div>
                    <h3 className="vertical-timeline-element-title">Co-fondateur</h3>
                    <h4 className="vertical-timeline-element-subtitle">ACC&S - Agence Web</h4>
                    <p>
                        Développement de site internet / intranet / extranet<br/>
                        Conseil en communication Web, référencement, formateur Internet
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--education"
                    date="1998 - 1999"
                    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
                    icon={<SchoolIcon />}
                >
                    <h3 className="vertical-timeline-element-title">Formation</h3>
                    <h4 className="vertical-timeline-element-subtitle">"Chef de projets"</h4>
                    <p>
                        au CESI - Ecole d'Ingénieurs
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="1997 - 1998"
                    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                    icon={<WorkIcon />}
                >
                    <h3 className="vertical-timeline-element-title">Chargé d'affaires</h3>
                    <h4 className="vertical-timeline-element-subtitle">ADS-AMMI</h4>
                    <p>
                        Orienté "grands comptes" - secteur Région Centre
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--education"
                    date="...  1996"
                    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
                    icon={<SchoolIcon />}
                >
                    <h3 className="vertical-timeline-element-title">Etudes supérieurs</h3>
                </VerticalTimelineElement>
            </VerticalTimeline>

        </div>
    );
};

export default Timeline;
