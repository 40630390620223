import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { AnimatePresence } from "framer-motion";
import Home from './pages/Home';
import Contact from './pages/Contact';
import { Presentation } from "./pages/Presentation";
import { Methodologie } from "./pages/Methodologie";
import { Outils } from "./pages/Outils";
import { Experience } from "./pages/Experience";
import { Portfolios } from "./pages/Portfolios";
import {Services} from "./pages/Services";


import ReactGA from "react-ga";


const App = () => {


    let location = useLocation()
    useEffect(() => {
        //ReactGA.ga('send', 'pageview', location.pathname);
        //ReactGA.send(['pageview', location.pathname])
        //if (!window.location.href.includes("localhost")) {
            console.log(location)
            ReactGA.initialize('UA-199999062-1',{debug: false});
            ReactGA.pageview(location.pathname)
        //}
    }, [location]);

/*
    let location = useLocation()
    useEffect(() => {
        //ReactGA.ga('send', 'pageview', location.pathname);
        //ReactGA.send(['pageview', location.pathname])
        if (!window.location.href.includes("localhost")) {
            console.log(location)
            //ga4react.initialize();

            const ga4react = new GA4React("G-QN5JF6KGJZ");
            ga4react.pageview(location.pathname);
        }
    }, [location]);
*/

    return (
        <AnimatePresence>
            <Switch >
                <Route exact path="/" component={Home} />
                <Route exact path="/services" component={Services} />
                <Route exact path="/presentation" component={Presentation} />
                <Route exact path="/methodologie" component={Methodologie} />
                <Route exact path="/experience" component={Experience} />
                <Route exact path="/outils" component={Outils} />
                <Route exact path="/portfolios" component={Portfolios} />
                <Route exact path="/contact" component={Contact} />
                <Redirect to="/" />
            </Switch>
        </AnimatePresence>
    );
};

export default App;
