import React from 'react';
import {motion} from "framer-motion";
import Navigation from "../components/Navigation";
import ButtonsBottom from "../components/ButtonsBottom";
import Logo from "../components/Logo";
import { variants, transition, leftVal, topVal, size } from "../paramAnim/Param";
import { projectsData } from '../data/projectsData';
import Portfolio from "../components/Portfolio";

export const Portfolios = () => {

    return (
        <main>
            <motion.div className="page"
                initial="out"
                animate="in"
                exit="out"
                variants={variants}
                transition={transition}>
                <Navigation />
                <Logo />

                <div className="main decal">
                    <div className="left">
                        <h1>Porfolios</h1>

                        <div className="spacer"></div>

                        <p>Actuellement en "mission longue" avec <b>Trinnov Audio</b>, je participe à la mise en oeuvre de leurs différents besoins.</p>

                        <p>Au préalable, mes  cinq dernières années ont été consacrées au projet <b>Dooliz</b> - gestionnaire d'invités.</p>

                        <p>Par ailleurs, j'ai pu réalisé de nombreux site internet, intranet et extranet lors de mes précédentes activités dont voici quelques exemples les plus marquants.</p>
                        
                    </div>
                    <div className="right">
                        <div className="portfolio">
                            <ul>
                                {projectsData.detail.map((item, i) => {
                                    return <Portfolio id={item.id} key={i} />
                                })}
                            </ul>
                        </div>
                    </div>

                </div>
                <ButtonsBottom left={'/outils'} right={'/methodologie'} />
                <span className="random-circle" style={{ left: leftVal, top: topVal, transform: size }}></span>
            </motion.div>
        </main>
    );
};
