export const MethodologieData = [
    {
        titre:"",
        text: "<p>La méthode de travail est très souvent le facteur le plus déterminant de la réussite d’un projet. <br/><br/>Une organisation efficace adaptée, permet d'aboutir au résultat final selon vos attentes et dans les délais impartis.</p><p>Ces dernières années la méthode \"Agile\" est apparue comme étant la méthode pour gérer des projets complexes. Pour autant, dans la plupart des cas, la méthode \"traditionnelle\" permettra de définir les points clès et détailler le projet pour une conduite optimisée.</p><p>Nous utiliserons de manière judicieuse un mixte des deux méthodes en intégrant des validations (et corrections) de conceptions aux moments opportuns.</p>",
    },
    {
        titre:"1. Clarifier l'idée",
        text: "<p>Votre activité nécessite un outil spécifique, votre besoin est donc identifié !</p> <p>Clarifier ce besoin est une phase nécessaire pour définir le futur cahier des charges qui sera le support de conception.</p> <p>Cette phase de clarification permet de \"coucher sur le papier\" les grandes lignes et soulever d'éventuels problèmatiques, voir d'identifier d'autres besoins.</p>",
    },
    {
        titre:"2. Etat des lieux",
        text: "<p>\"Petit tour du propriétaire\" !</p><p>Le projet nécessite d'interagir avec d'autres informations ?<br/>l'hébergement du projet ?<br/> le nom de domaine ?<br/> les serveurs et outils déjà en place ?<br/>...</p><p> Cet état des lieux permet de mieux connaitre l'environnement  et aura un impact pour accueillir le projet et/ou nous guider dans le choix de la technologie.</p>",
    },
    {
        titre:"3. Analyse, Synthèse, recherche de solutions",
        text: "<p>En réunissant les deux premiers points, des solutions s'ouvrent !</p> <p>Cette phase utile permet de centraliser la demande, de contrôler la faisabilité ou, dans le cas contraire, de rechercher les solutions pour aboutir à la réalisation.</p>",
    },
    {
        titre:"4. Rédaction du cahier des charges",
        text: "<p>Il est l'outil du développeur !</p><p>C'est sur la base du cahier des charges que la conception du projet va se réaliser.</p><p>Il intégre donc toutes les fonctionnalités du besoin, l'arborescence des pages, leurs contenus. Les entrées et sorties des données, l'entrée et sortie de l'utilisateur...</p><p>Selon la complexité du projet, le cahier des charges peut intégrer des maquettes des pages (validant l'ergonomie, la présentation...). </p>",
    },
    {
        titre:"5. Budget prévisionnel",
        text: "<p>La rédaction du cahier des charges faite, une idée du \"temps d'investissements\" se dessine. Il est donc possible de mettre un chiffre sur le budget à allouer au projet.</p>",
    },
    {
        titre:"6. Plan d'actions",
        text: "<p>Un calendrier de réalisation est mis en place en fonction de l'ordre, l'importance et la difficulté de réalisation.</p><p>Ce calendrier sera le fil conducteur pour mener dans les délais la mission confiée.</p>",
    },
    {
        titre:"7a. Réalisation",
        text: "<p>Comme l'artiste devant sa toile encore blanche, le projet va prendre forme.</p><p>Tous les points évoqués dans le cahier des charges seront mis en oeuvre. La conception sera technique et tiendra compte des spécificités definies lors de la phase d'étude.</p><p>Selon l'agenda, les composants développés, ou en cours de développement, des \"allers / retours\" vers le client seront mis en place afin de valider ensemble la bonne intégration. Ils seront une \"validation\" du besoin défini par le cahier des charges.</p>",
    },
    {
        titre:"7b. Tests client, optimisation",
        text: "<p>\"Allers / retours\" avec la conception afin de valider certains points.</p><p>Ils permettent d'apporter d'éventuelles corrections ou adaptations en fonction de l'importance du point. </p><p>Cette étape peut être également la \"validation\" du process.</p>",
    },
    {
        titre:"8. Livraison",
        text: "<p>La livraison constitue la dernière phase du projet.</p><p>Bien que certains points ont pu être validés auparavant (lors de la phase de test), la livraison valide l'ensemble du projet et met fin à la période de conception.</p>",
    },
    {
        titre:"9. Maintenance",
        text: "<p>Votre projet est en place et répond à toutes vos attentes.</p><p>Certains projets n'ont pas besoin de maintenance et vont fonctionner durant des années. </p><p>D'autres projets vont nécessiter des évolutions (en fonction de votre marché, de votre besoin qui évolue...), je reste à disposition pour répondre aux questions et effectuer avec vous ces évolutions.</p>",
    },
];
