import React from 'react';
import { motion } from "framer-motion";
import { TagCloud } from 'react-tagcloud';

import Navigation from "../components/Navigation";
import ButtonsBottom from "../components/ButtonsBottom";
import Logo from "../components/Logo";
import { variants, transition, leftVal, topVal, size } from "../paramAnim/Param";



const projet = [
    { value: 'Application métier', count: 38 },
    { value: 'Internet', count: 30 },
    { value: 'Base de données', count: 16 },
    { value: 'Graphisme', count: 22 },
    { value: 'Intranet', count: 28 },
    { value: 'Front-end', count: 18 },
    { value: 'Back-end', count: 15 },
    { value: 'Design', count: 20 },
    { value: 'Extranet', count: 25 },
];

const personne = [
    { value: 'Flexible', count: 38 },
    { value: 'Organisé', count: 30 },
    { value: 'Réactif', count: 28 },
    { value: 'Compétitif', count: 25 },
    { value: 'Passionné', count: 16 },
    { value: 'Perfectionniste', count: 18 },
    { value: 'Collaboratif', count: 15 },
    { value: 'Satisfaction client', count: 20 },
    { value: 'Maîtrise du budget', count: 22 },
    { value: 'Rapide', count: 22 },
    { value: 'Economique', count: 22 },
    { value: 'Travail de qualité', count: 22 },
    { value: 'Force de proposition', count: 22 },
];

const mission = [
    { value: 'En solo', count: 30 },
    { value: 'Courte durée', count: 28 },
    { value: 'Mobile', count: 18 },
    { value: 'Longue durée', count: 25 },
    { value: 'En équipe', count: 38 },
    { value: 'Télétravail', count: 16 },
];




export const Services = () => {

    return (
        <main>
            <motion.div className="page"
                initial="out"
                animate="in"
                exit="out"
                variants={variants}
                transition={transition}>
                <Navigation />
                <Logo />


                <div className="main ">

                    <div className="left">
                        <h1>Mes services</h1>

                        <div className="spacer"></div>

                        <TagCloud
                            minSize={16}
                            maxSize={30}
                            tags={projet}
                            colorOptions={{
                                luminosity: 'light',
                                hue: 'monochrome',
                            }}
                        />

                        <div className="spacer"></div>
                        <TagCloud
                            minSize={16}
                            maxSize={30}
                            tags={personne}
                            colorOptions={{
                                luminosity: 'light',
                                hue: 'monochrome',
                            }}
                        />

                        <div className="spacer"></div>
                        <TagCloud
                            minSize={16}
                            maxSize={30}
                            tags={mission}
                            colorOptions={{
                                luminosity: 'light',
                                hue: 'monochrome',
                            }}
                        />



                    </div>
                    <div className="right decal">

                        <p>Je propose mes services de développement en tant que freelance.</p>
                        <p>J'interviens sur des missions de courtes ou longues durées.</p>
                        <p>Mes compétences s'appliquent pour de la conception d'applications métiers (internet / extranet) ou pour le développement et l'accompagnement d'une communication internet.</p>
                        <p>Par mon expérience et mon expertise, je peux prendre en charge l'ensemble des postes nécessaires pour votre projet ou réaliser des modules spécifiques. </p>
                        <ul>
                            <li>Analyse du besoin,</li>
                            <li>Recommandations, force de proposition,</li>
                            <li>Sélection du langage de développement,</li>
                            <li>Architecture et création de bases de données,</li>
                            <li>Réalisation d'interface utilisateur (front / back office),</li>
                            <li>Design, ergonomie,</li>
                            <li>Intégration graphique,</li>
                            <li>Déploiement sur serveur...</li>
                        </ul>
                        <p>&nbsp;</p>
                        <p>En relation avec d'autres freelanceurs, je suis en mesure de monter une équipe pour des demandes plus importantes et diversifiées.</p>





                    </div>
                </div>


                <ButtonsBottom left={'/'} right={'/outils'} />
                <span className="random-circle" style={{ left: leftVal, top: topVal, transform: size }}></span>
            </motion.div>
        </main>
    );
};

