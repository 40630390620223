export const OutilsData = {
  "major": [
    {
      id: 'html',
      title: 'Html',
      color: '#E44D26',
      progress: '99',
      img: './assets/logo/html5.svg'
    },
    {
      id: 'css',
      title: 'Css',
      color: '#2062AF',
      progress: '90',
      img: './assets/logo/css3.svg'
    },
    {
      id: 'sass',
      title: 'Sass',
      color: '#CF649A',
      progress: '85',
      img: './assets/logo/sass.svg'
    },
    {
      id: 'bootstrap',
      title: 'Bootstrap',
      color: '#563D7C',
      progress: '99',
      img: './assets/logo/bootstrap.svg'
    },
    {
      id: 'vuetify',
      title: 'Vuetify',
      color: '#563D7C',
      progress: '99',
      img: './assets/logo/vuetify.svg'
    },
    {
      id: 'php',
      title: 'Php',
      color: '#8C9BD6',
      progress: '98',
      img: './assets/logo/php.svg'
    },
    {
      id: 'http',
      title: 'Http',
      color: '#005B9B',
      progress: '80',
      img: './assets/logo/http.svg'
    },
    {
      id: 'javascript',
      title: 'Javascript',
      color: '#F7DF1E',
      progress: '98',
      img: './assets/logo/javascript.svg'
    },
    {
      id: 'jquery',
      title: 'Jquery',
      color: '#21609B',
      progress: '98',
      img: './assets/logo/jquery.svg'
    },
    {
      id: 'nodejs',
      title: 'NodeJs',
      color: '#689F63',
      progress: '30',
      img: './assets/logo/nodejs.svg'
    },
    {
      id: 'mysql',
      title: 'MySql',
      color: '#F29111',
      progress: '90',
      img: './assets/logo/mysql.svg'
    },
    {
      id: 'sqlite',
      title: 'SqlLite',
      color: '#21609B',
      progress: '90',
      img: './assets/logo/sqlite.svg'
    },
    {
      id: 'phpstorm',
      title: 'PhpStorm',
      color: '#B345F1',
      progress: '80',
      img: './assets/logo/phpstorm.svg'
    },
    {
      id: 'symfony',
      title: 'Symfony',
      color: '#000000',
      progress: '70',
      img: './assets/logo/symfony.svg'
    },
    {
      id: 'webpack',
      title: 'Webpack',
      color: '#8ED6FB',
      progress: '40',
      img: './assets/logo/webpack.svg'
    },
    {
      id: 'git',
      title: 'Git',
      color: '#DE4C36',
      progress: '60',
      img: './assets/logo/git.svg'
    },
    {
      id: 'docker',
      title: 'Docker',
      color: '#21609B',
      progress: '25',
      img: './assets/logo/docker.svg'
    },
    {
      id: 'api',
      title: 'API',
      color: '#38A9B4',
      progress: '20',
      img: './assets/logo/api.svg'
    },
    {
      id: 'vuejs',
      title: 'VueJs',
      color: '#41B883',
      progress: '40',
      img: './assets/logo/vuejs.svg'
    },

  ],
  "minor": [
    {
      id: 'react',
      title: 'React',
      color: '#53C1DE',
      progress: '45',
      img: './assets/logo/react.svg'
    },
    {
      id: 'flutter',
      title: 'Flutter',
      color: '#21609B',
      progress: '15',
      img: './assets/logo/flutter.svg'
    }
  ]
};
