import React, {useState} from 'react';
import Navigation from "../components/Navigation";
import Logo from "../components/Logo";
import {variants, transition} from "../paramAnim/Param";
import ContactForm from "../components/ContactForm";
import ButtonsBottom from "../components/ButtonsBottom";
import { motion } from "framer-motion";
import CVForm from "../components/CVForm";
import Modal from "react-modal";
Modal.setAppElement("#root");



const Contact = () => {
    const [isOpen, setIsOpen] = useState(false);

    function handlerSetIsOpen() {
        setIsOpen(!isOpen);
    }


    return (
        <main>
            <motion.div className="page"
                initial="out"
                animate="in"
                exit="out"
                variants={variants}
                transition={transition}>
                <Navigation />
                <Logo />

                <div className="main decal">

                    <div className="left">
                        <h2>Contactez-moi</h2>

                        <div className="spacer"></div>

                        <p>Mon profil a retenu votre attention, je vous en remercie.<br/>
                            <a href="#/" onClick= { ()=> setIsOpen(!isOpen) }>Cliquez ici</a> pour télécharger mon expérience au format pdf
                        </p>

                        <p>Pour de plus amples informations sur mes services, mes expériences ou ma méthode de travail, laissez-moi un message. Je vous répondrai sans tarder.</p>

                        <CVForm isOpen={isOpen} action={handlerSetIsOpen} />

                    </div>
                    <div className="right">
                        <ContactForm />


                    </div>
                </div>
                <ButtonsBottom left={'/presentation'} />

            </motion.div>

        </main>
    );
};

export default Contact;
